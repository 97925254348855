function SearchIcon() {
  return (
    <button className="search-icon">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.77708 11.1396C4.27986 11.1396 3.00417 10.6201 1.95 9.58125C0.911111 8.52708 0.391667 7.25139 0.391667 5.75417C0.391667 4.25694 0.911111 2.98889 1.95 1.95C3.00417 0.895832 4.27986 0.368748 5.77708 0.368748C7.27431 0.368748 8.54236 0.895832 9.58125 1.95C10.6201 2.98889 11.1396 4.25694 11.1396 5.75417C11.1396 6.38055 11.0326 6.98403 10.8188 7.56458C10.6201 8.12986 10.3451 8.63403 9.99375 9.07708L15.15 14.2333C15.2875 14.3556 15.3563 14.5007 15.3563 14.6687C15.3563 14.8368 15.2875 14.9896 15.15 15.1271C15.0278 15.2646 14.875 15.3333 14.6917 15.3333C14.5236 15.3333 14.3785 15.2646 14.2563 15.1271L9.07708 9.97083C8.61875 10.3375 8.10694 10.6278 7.54167 10.8417C6.97639 11.0403 6.38819 11.1396 5.77708 11.1396ZM5.77708 9.85625C6.92292 9.85625 7.88542 9.45903 8.66458 8.66458C9.45903 7.87014 9.85625 6.9 9.85625 5.75417C9.85625 4.60833 9.45903 3.63819 8.66458 2.84375C7.88542 2.0493 6.92292 1.65208 5.77708 1.65208C4.63125 1.65208 3.66111 2.0493 2.86667 2.84375C2.07222 3.63819 1.675 4.60833 1.675 5.75417C1.675 6.9 2.07222 7.87014 2.86667 8.66458C3.66111 9.45903 4.63125 9.85625 5.77708 9.85625Z"
          fill="#FF8D00"
        />
      </svg>
    </button>
  );
}

export { SearchIcon };
