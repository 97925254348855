import { SearchIcon } from "./SearchIcon";
import { SearchSubmitIcon } from "./SearchSubmitIcon";

function Search() {
  return (
    <div className="search">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="88"
        height="88"
        viewBox="0 0 88 88"
        fill="none"
      >
        <path
          d="M81.488 21.9472L60.676 9.9352C57.5344 8.1224 53.6712 8.1224 50.5296 9.9352L29.7176 21.9472C26.576 23.76 24.64 27.1128 24.64 30.7384V32.7624C23.452 31.0464 21.8416 29.4272 19.8704 28.0808C16.544 25.8192 12.8744 24.7896 9.7504 24.992C10.5248 28.2216 12.936 31.6008 16.5616 34.0648C17.424 34.6544 18.3128 35.156 19.2104 35.5784C17.1336 35.7896 14.9864 36.2736 12.848 37.0656C7.7 38.9752 3.6784 42.24 1.4432 45.8568C5.632 47.5376 11.2728 47.5288 16.8608 45.4608C19.888 44.3344 22.528 42.7416 24.64 40.8848V54.7624C24.64 58.388 26.576 61.7408 29.7176 63.5536L50.5296 75.5656C53.6712 77.3784 57.5344 77.3784 60.676 75.5656L65.3312 72.8728L76.6568 79.3056C76.956 79.4728 77.2904 79.4552 77.5544 79.2968C77.8184 79.1472 78.0032 78.8656 77.9944 78.5136L77.8976 65.6128L81.4792 63.5448C84.6208 61.732 86.5568 58.3792 86.5568 54.7536V30.7208C86.5568 27.1128 84.6208 23.76 81.488 21.9472ZM55.5984 56.144C29.4888 56.144 29.3216 40.2512 29.6384 36.5816C29.6736 36.1944 30.052 35.9392 30.4128 36.0536C40.1544 39.1424 55.5984 38.8432 55.5984 38.8432C55.5984 38.8432 71.0424 39.1424 80.784 36.0536C81.1536 35.9392 81.532 36.1944 81.5584 36.5816C81.8752 40.2512 81.708 56.144 55.5984 56.144Z"
          fill="#FF8D00"
        />
        <path
          d="M52.5096 44.9944C42.9792 44.9592 38.324 42.152 37.4264 41.5448C37.356 41.492 37.2504 41.5448 37.2504 41.6328C37.2064 42.5568 37.3736 46.3936 42.8032 47.8896C49.676 49.7904 52.1488 46.0152 52.6152 45.1528C52.6592 45.0824 52.5976 44.9944 52.5096 44.9944Z"
          fill="#FF8D00"
        />
        <path
          d="M72.4592 41.5448C71.5616 42.152 66.9064 44.9592 57.376 44.9944C57.288 44.9944 57.2352 45.0824 57.2704 45.1616C57.7368 46.0152 60.2096 49.7992 67.0824 47.8984C72.512 46.3936 72.6792 42.5656 72.6352 41.6416C72.6264 41.5448 72.5296 41.5008 72.4592 41.5448Z"
          fill="#FF8D00"
        />
      </svg>
      <div className="input-wrapper">
        <input placeholder="Type anything here..." />
        <SearchSubmitIcon />
        <SearchIcon />
      </div>
    </div>
  );
}

export { Search };
