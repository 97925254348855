function SearchSubmitIcon() {
  return (
    <button className="search-submit">
      <svg
        width="15"
        height="12"
        viewBox="0 0 15 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.0271 6.73333L1.56042 11.8208C1.30069 11.9278 1.05625 11.9049 0.827084 11.7521C0.597917 11.5993 0.483334 11.3854 0.483334 11.1104V0.889583C0.483334 0.614582 0.597917 0.400693 0.827084 0.247915C1.05625 0.0951381 1.30069 0.0722218 1.56042 0.179166L14.0271 5.26667C14.3479 5.40417 14.5083 5.64861 14.5083 6C14.5083 6.35139 14.3479 6.59583 14.0271 6.73333ZM1.76667 10.3542L12.4 6L1.76667 1.62292V4.67083L6.46458 6L1.76667 7.32917V10.3542ZM1.76667 10.3542V6V1.62292V4.67083V7.32917V10.3542Z"
          fill="white"
        />
      </svg>
    </button>
  );
}

export { SearchSubmitIcon };
