import { Logo } from "./Logo";

function Header() {
  return (
    <header className="header">
      <a href="/" className="link">
        {" "}
        <Logo />
      </a>
      <button>Contact Us</button>
    </header>
  );
}

export { Header };
